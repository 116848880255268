import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';

import EposNavbar from './navbar/EposNavbar';
import InputAmount from './general/InputAmount';
import PaymentActive from './general/PaymentActive';
import PaymentComplete from './general/PaymentComplete';
import PaymentCancelled from './general/PaymentCancel'

import QRCodeDisplay from './general/QRCodeLink';


import url from '../api/url';
import MainLayout from './main/MainLayout';
  
const Layout = ({connectedUsers, loader, paymentCancelled, paymentActive, paymentComplete, socketId, setPaymentActive, setPaymentComplete, setPaymentCancelled, setLoader}) => {

    const [amount, setAmount] = useState('0.00')

    const [QRCode, setQRCode] = useState(false);
    const [QRCodeLink, setQRCodeLink] = useState(null);




    useEffect(() => {

        if(QRCodeLink) {
            setQRCode(true)
        }

    }, [QRCodeLink])



    return(


                    <Container fluid>
            <Row>
                <EposNavbar />
            </Row>
            {
                loader ? "Loading..." 

                :

                <Row>
                    <div className="main-container">
                        {
                            paymentActive ?

                            <PaymentActive setPaymentActive={setPaymentActive}/>

                            :

                            paymentComplete ?

                            <PaymentComplete setPaymentComplete={setPaymentComplete}/>

                            :

                            paymentCancelled ?

                            <PaymentCancelled setPaymentCancelled={setPaymentCancelled}/>

                            :

                            QRCode ? <QRCodeDisplay socketId={socketId} setQRCode={setQRCode} amount={amount} QRCodeLink={QRCodeLink}/>: <InputAmount socketId={socketId} setPaymentActive={setPaymentActive} connectedUsers={connectedUsers} amount={amount} setAmount={setAmount} setQRCodeLink={setQRCodeLink} setLoader={setLoader}/>
                        }
                    </div>
                </Row>

            }
            

        </Container>

    
    )
}

export default Layout;