import React from 'react'
import { QRCode } from 'react-qrcode-logo';
import Button from '@material-ui/core/Button';


function QRCodeDisplay({QRCodeLink, amount, setQRCode, socketId}) {

    const handleCancel = () => {
        setQRCode(false);
    }
    
    return (
        <div className="qrcode-container">
            <h1>£{amount}</h1>
            <QRCode style={{display: 'inline'}} qrStyle="dots" size="220" bgColor="#fff" fgColor="#65ccb8" value={`${QRCodeLink}&merchant_socket_id=${socketId}`}/>
            <p>Please tell the customer to scan this QR code with their camera phone.</p>
            <Button color="secondary" disableElevation onClick={handleCancel}>Cancel</Button>
        </div>
    )
}

export default QRCodeDisplay
