import React,{useState,useEffect} from 'react';
import {Container, Row, Col,Card,ListGroup,ListGroupItem,Table,CardTitle} from 'reactstrap'
import EposNavbar from './../navbar/EposNavbar';
import axios from 'axios';
import url from '../../api/url';
import Cookies from 'js-cookie';
var DatePicker = require("reactstrap-date-picker");
export default function OrderSummary() {

  let today = new Date().toISOString().slice(0, 10)
  const [date,setDate] = useState(today)
  const [data,setData] = useState({ total_volume:0,
    total_paid_card:0,
    total_paid_banking:0,
    banking_payments:[]})


  useEffect(() => {
    async function getData(){
      await axios.get(`${url}/payment/order-summary/${date}`, {
        headers: {
            'x-auth-token': Cookies.get('token')
        }
    }).then(response => {
        console.log(response.data.order_summary);
        setData(response.data.order_summary)
    }).catch(err => {
        console.log(err.response)
    })
    }

     getData()

  },[date])
  
  function handleChange(value) {
    setDate(value.slice(0, 10)) // ISO String, ex: "2016-11-19T12:00:00.000Z")
  }

  return( 
    <Container fluid >
    <Row>
        <EposNavbar />
    </Row>
    <div className="main-layout-container" style={{minWidth:"800px"}}>
    <Row>
    <DatePicker style={{width:"500px"}} id      = "example-datepicker" 
                    value   = {date} 
                    onChange= {(v) => handleChange(v)}
                   maxDate={today}
                    />
                    
    </Row>
    <Row style={{paddingTop:"20px"}}>
      <Card >
      <ListGroup style={{width:"500px"}}>
        <ListGroupItem style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>Total Volume <p > £{(data?.total_volume/100).toFixed(2)} </p></ListGroupItem>
        <ListGroupItem style={{display:"flex",justifyContent:"space-between"}}>Card Payments<p >£{(data?.total_paid_card/100).toFixed(2)}</p></ListGroupItem>
        <ListGroupItem style={{display:"flex",justifyContent:"space-between"}}>Bank Payments <p > £{(data?.total_paid_banking/100).toFixed(2)}</p></ListGroupItem>
      </ListGroup>
      </Card>
    </Row>
    {
      data.total_paid_banking > 0?
    <Row style={{paddingTop:"20px"}}>
      <Card >
      <CardTitle style={{fontWeight:"bold", paddingTop:"5px", paddingLeft:"250px"}}>Bank Payment Details</CardTitle>
    <Table responsive="sm" style={{width:"500px"}}>
    <thead>
      <tr>
        <th>#</th>
        <th>Payment Id</th>
        <th>Customer Email</th>
        <th>Reference</th>
        <th>Amount</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      {
        data.banking_payments?.map((data,index)=>(
      <tr key={index}>
        <td>{index +1}</td>
        <td>{data.payment_id}</td>
        <td>{data.customer_email}</td>
        <td>{data.remitter_reference}</td>
        <td>£{(data.amount/100).toFixed(2)}</td>
        <td>{data.created.slice(11,19)}</td>
      </tr>
        )
        )
      }
    </tbody>
  </Table>
  </Card>
    </Row>
  :
  null
    }
    </div>
</Container>
  
  )
}
