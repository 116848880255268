import React, {useState, useContext, useEffect} from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom';

import UserContext from './context/UserContext';
import AuthContext from './context/AuthContext';  

import axios from 'axios';
import Cookies from 'js-cookie';
import PrivateRoute from './PrivateRoute';

import url from './api/url';

import AuthLayout from './components/authentication/AuthLayout'
import Layout from './components/Layout'; 
import MainLayout from './components/main/MainLayout';
import PrivateRouteLayout from './components/PrivateRouteLayout';
import HandleError from './components/errorHandler/HandleError';




function App() {

  const [userData, setUserData] = useState({});
  const [auth, setAuth] = useState(true);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const token = Cookies.get('token')

    const authenticate = async() => {
      try {

        await axios.get(`${url}/business/authenticate-store`, 
        {
          headers: {
            'x-auth-token': token
          }
        }
      ).then(res => {
        setAuth(true);
        setUserData(res.data)
        setLoading(false);
      }).catch(err => {
        setAuth(false);
        setLoading(false);

        console.log(err, "error")
        if (err.response.status == 401){
          Cookies.remove('token')
          window.location.href = "/"
        }
      })

      } catch (error){
        console.log(error)
      }
    }

    authenticate();

  }, [auth])

  return (
    <div>

    <AuthContext.Provider value={{auth, setAuth}}>
      <UserContext.Provider value={{userData, setUserData}}>
        <Router>
          {/* Routes */}

          {

            loading ? <h3>Loading...</h3>

            :

          <>
          <Switch>
          <Route exact path="/login">
          {auth ? <Redirect to={{
        pathname: "/",
        state: { from: "/login" }
      }}/> :  <AuthLayout />}
           
          </Route>
            <PrivateRoute  path="/" component={PrivateRouteLayout}/>
          </Switch>
          </>
          }

         
        </Router>
      </UserContext.Provider>
    </AuthContext.Provider>

    </div>
  );
}

export default App;
