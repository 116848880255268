import React from 'react'

import {Container, Row, Col} from 'reactstrap'

import { Link } from 'react-router-dom'

function UsersContainer({users, setUserSocketId, setMainLayoutShown}) {

    return (
        <div className="users-container">

            <div style={{padding: "20px", alignSelf: 'center'}}>
                <h2 style={{textAlign: 'center'}}>Users ready to pay</h2>
            </div>

            {
                users.map(user => {
                    return(

          
                           <Link to={`/terminal/?customer_id=${user.vizopay_customer_id}&payment_session_id=${user.current_payment_session_id}`}>
                                <Container  className="user-container">
                            <Row>

                                <Col className="user-avatar-wrapper">
                                <div className="user-avatar">
                                    <span style={{fontWeight: '700', fontSize: 20, color: '#fff'}}>{user.first_name.substring(0, 1)}</span>
                                </div>
                            </Col>
                            <Col className="user-email">
                                <span style={{fontWeight: '800', fontSize: 14, fontFamily: 'Avenir'}}>{user.first_name}</span>
                            </Col>
                            <Col className="user-connected">
                                <span style={{fontWeight: '800', fontSize: 14, fontFamily: 'Avenir', color: '#65ccb8'}}>Connected</span>
                            </Col>

                            </Row>
                            
                        </Container>
                           </Link>

                    )
                })
            }
        </div>
    )
}

const styles={
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
}

export default UsersContainer
