import React, {useContext, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from './context/UserContext';
import AuthContext from './context/AuthContext';

export const PrivateRoute = ({component: Component, ...rest }) => {

const { auth } = useContext(AuthContext);


return(
  <Route
    {...rest}
    render={(props) =>
     auth ? (
        <Component {...props} />
      ) : (
        <Redirect to={{pathname: "/login"}}/>
      )
    }
  />
)
};

export default PrivateRoute;