import React from 'react';

import {Spinner} from 'reactstrap'
import Button from '@material-ui/core/Button';

import { useHistory } from "react-router-dom";

const PaymentActive = ({setPaymentActive}) => {

  const history = useHistory();

    const handleCancel = () => {
        setPaymentActive(false);
        history.push("/");
    }

    return(

        <div>
            <div style={{display: 'flex', flexGrow: 2, justifyContent: 'center', alignItems: 'center'}}>
                <Spinner style={{color: '#65ccb8'}}/>
            </div>
            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                <h3>Customer is completing payment. Please wait...</h3>
            </div> 
            <div style={{display: 'flex', flexGrow: 1, flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Button color="secondary" disableElevation onClick={handleCancel}>Cancel</Button>

            </div>
        </div> 

    )

}

export default PaymentActive;