import React, {useState, useEffect} from 'react'
import {Switch, Route} from 'react-router-dom'
import PrivateRoute from '../PrivateRoute';
import Layout from './Layout';
import MainLayout from './main/MainLayout';
import HandleError from './errorHandler/HandleError';
import socketIOClient from "socket.io-client";
import url from '../api/url';
import Cookies from 'js-cookie';
import axios from 'axios';
import OrderSummary from './main/OrderSummary';

function PrivateRouteLayout() {

          const [paymentActive, setPaymentActive] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentCancelled, setPaymentCancelled] = useState(false);
    
    const [loader, setLoader] = useState(false);

    const [socketId, setSocketId] = useState('');

    const [connectedUsers, setConnectedUsers] = useState([]);

    // fetch connected users 

    const fetchConnectedUsers = async() => {
        try {

            await axios.get(`${url}/payment/direct/connected-customers`, {
                headers: {
                    'x-auth-token': Cookies.get('token')
                }
            }).then(response => {
                console.log(response.data);
                setConnectedUsers(response.data)
            }).catch(err => {
                if (err.response.status == 401){
                    Cookies.remove('token')
                    window.location.href = "/"
                  }
            })
            
        } catch (error) {
            return error.message
        }
    }

    useEffect(() => {
        fetchConnectedUsers();
    }, [])

    const [expired, setExpiredUser] = useState({}) 

    // useEffect(() => {

    //     const checkUsers = () => {
    //         connectedUsers.map((user) => {
                
    //     const date_now = new Date();

    //     let diffMs = (date_now - user.time_connected_store);
    //     var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

    //     if(diffMin >= 2) {
    //         setExpiredUser(user)
    //     }


    //         })
    //     }

    //     const interval = setTimeout(() => {

    //         checkUsers()

    //     }, [120000])

    //     return () => clearInterval(interval)

    // }, [])

    // useEffect(() => {


    // }, [expired])


          // GET SOCKET ID AND STORE


    useEffect(() => {
                 const socket = socketIOClient(`${url}`, {
            path: "/epos/socket",
            withCredentials: true,
            transportOptions: {
            polling: {
                extraHeaders: {
                    "my-custom-header": "abcd"
                }
            }
        }
    });

    socket.on('connect', () => {

        console.log('Connected to socket with ' + socket.id)

        setSocketId(socket.id)
    })

    socket.on('user', (data) => {
        console.log(data, "user store socket");

        fetchConnectedUsers();
    })

    socket.on('message', (data) => {
        if(data.status) {
            console.log(data)

        if(data.status === "started") {
            setPaymentActive(true);
        }

        else if(data.status === "complete") {
            setPaymentActive(false);
            setPaymentComplete(true);
        }

        else if(data.status === "cancelled") {
            setPaymentActive(false);
            setPaymentCancelled(true);
        }
        } else {
            return
        }

    })

    }, [])



    useEffect(() => {

        const saveSocketId = async() => {

            await axios.post(`${url}/payment/terminal/save/socket`, {
                terminal_socket_id: socketId
            }, {
                headers: {
                    'x-auth-token': Cookies.get('token')
                }
            })
            .then(response => {
                console.log(response, "save socket id")
            })
            .catch(err => {
                console.log(err.response, "response for socket")
                HandleError(err.response.status)
            })

        }

        if(socketId) {

            saveSocketId()

        } else {

            return;

        }

    }, [socketId])

    return (
      
        <>
              <PrivateRoute exact path="/" component={() => {
                return(
                    <MainLayout connectedUsers={connectedUsers} socketId={socketId} paymentActive={paymentActive} paymentCancelled={paymentCancelled} paymentComplete={paymentComplete} loader={loader} setPaymentActive={setPaymentActive} setPaymentComplete={setPaymentComplete} setPaymentCancelled={setPaymentCancelled} setLoader={setLoader} />
                )
              }}/>
              <PrivateRoute exact path="/terminal" component={() => {return(
                <Layout connectedUsers={connectedUsers} socketId={socketId} paymentActive={paymentActive} paymentCancelled={paymentCancelled} paymentComplete={paymentComplete} loader={loader} setPaymentActive={setPaymentActive} setPaymentComplete={setPaymentComplete} setPaymentCancelled={setPaymentCancelled} setLoader={setLoader}/>
              )}}/>
              <PrivateRoute exact path="/order" component={() => {return(
                <OrderSummary/>
              )}}/>
        </>
       
    )
}

export default PrivateRouteLayout
