import React, {useState, useEffect, useContext} from 'react'
import {Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Row, Col} from 'reactstrap';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Icon from '@material-ui/core/Icon';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

import './authentication.css'

import axios from 'axios';
import url from '../../api/url';
import Cookies from 'js-cookie';

import AuthContext from '../../context/AuthContext';
import UserContext from '../../context/UserContext';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { makeStyles } from '@material-ui/core/styles';
import HandleError from '../errorHandler/HandleError';
import {toast,ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
  },
}));

function Login({setLogin}) {

      const classes = useStyles();

    const {auth, setAuth} = useContext(AuthContext);
    const {userData, setUserData} = useContext(UserContext);

    const [storeId, setStoreId] = useState( localStorage.getItem('storeId') ? localStorage.getItem('storeId') : '' );
    const [password, setPassword] = useState( localStorage.getItem('storeKey') ? localStorage.getItem('storeKey') : '' );

    const [spinner, setSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [saveDetails, setSaveDetails] = useState(true);

    const handleSubmit = async(e) => {
        e.preventDefault();

        setSpinner(true);

        if(saveDetails) {
          localStorage.setItem("storeId", storeId)
        localStorage.setItem("storeKey", password)
        localStorage.setItem("detailsSaved", saveDetails)
        } else {
              localStorage.clear()
        }

        await axios.post(`${url}/business/store/login`, 
            {
                store_id: storeId,
                store_password: password
            }
        ).then(res => {
            console.log(res.data);

            setUserData({
              store_name: res.data.store_name,
              store_id: res.data.store_id
            })

            Cookies.set('token', res.data.token);

            setAuth(true);
        }).catch(err => { 
            setSpinner(false);
            setErrorMessage(true);
            if (err.response.status == 401){
              Cookies.remove('token')
              window.location.href = "/"
            }
            HandleError(err.response.status, "Incorrect username or Password.")
        })
    }
    
    return (
        <div className="login-container">
          <ToastContainer  />
        <div className="lock-icon-container">
            <div className="lock-icon-circle">
  <LockRoundedIcon fontSize="large" className="lock-icon"/>

            </div>
        </div>
        <div className="header">
            <h2>Login</h2>
        </div>
        <Form autoComplete="on" className="auth-form" onSubmit={handleSubmit}>
                 <FormGroup>
                    <TextField value={storeId} onChange={(e) => setStoreId(e.target.value)} className="form-input" type="tel" id="outlined-basic" label="Store ID" size="medium" variant="outlined" />
                 </FormGroup>
                 <FormGroup>
                    <TextField value={password} onChange={(e) => setPassword(e.target.value)} className="form-input" type="password" id="outlined-basic" label="Password" size="medium" variant="outlined" />
                 </FormGroup>
                  <FormGroup>
      <FormControlLabel control={<Checkbox onChange={(e) => setSaveDetails(e.target.value === "on" ? true : false)} style={{color: '#65ccb8'}} defaultChecked={saveDetails} />} label="Save login details" />
    </FormGroup>

                <Button className="login-button" onClick={handleSubmit} size="large" variant="contained"  disableElevation>
                         {
                             spinner ?

                            <CircularProgress size="small" className="login-spinner"/>

                             :

                             "Login"

                         }
      
    </Button>
        </Form>
        </div>
         
    )
}

export default Login
