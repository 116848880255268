import React, {useState, useContext} from 'react';
import {Container, Row, Col, Input} from 'reactstrap';
import Terminal from './Terminal';

import Button from '@material-ui/core/Button';

import {Redirect, Link} from 'react-router-dom'

import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';
import {toast,ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import HandleError from './../errorHandler/HandleError';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import UserContext from '../../context/UserContext';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #F4A442',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};


const InputAmount = ({connectedUsers, setPaymentActive, amount, setAmount, setQRCodeLink, socketId, setLoader}) => {


     const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const [spinner, setSpinner] = useState(false);

    const {userData} = useContext(UserContext);

        var url_string = window.location.href
        var front_url = new URL(url_string);

        var payment_session_id = front_url.searchParams.get("payment_session_id");
        var customer_id = front_url.searchParams.get("customer_id");

        const [stamps, setStamps] = useState(0)
        const [percentage, setPercentage] = useState(0)

    // const CreatePaymentLink = async(e) => {
    //     e.preventDefault();

    //     let finalAmount = Number(amount*100)

    //     await axios.post(`${url}/payment/terminal/checkout`, {
    //         amount: finalAmount
    //     }, {
    //         headers: {
    //             'x-auth-token': Cookies.get('token')
    //         }
    //     }).then(res => {
    //         console.log(res.data);
    //         if(res.status === 200) {
    //             setQRCodeLink(res.data.redirecturl)
    //         }
    //     })
    //         .catch(err => console.log(err.response))
    // }

    const [returnHome, setReturnHome] = useState(false)

    const createPaymentAmount = async() => {

        console.log("charge created")

        await axios.post(`${url}/payment/terminal/charge`, 
        {
            
                payment_session_id: payment_session_id,
                customer_id: customer_id,
                amount: amount,
                terminal_socket_id: socketId,
                stamp_number: stamps,
                percentage_discount: percentage
            },
            {
                headers: {
                    'x-auth-token': Cookies.get("token")
                }
            }
        )
        .then(response => {
            setPaymentActive(true)
            console.log(response, "Payment charge created")
        })
        .catch(err => {
            
            if (err.response.status === 401){
                
                HandleError(err.response.status, "That didn't work... It's probably because this payment session has expired!")
              } else {
                  HandleError(err.response.status, "Couldn't create payment charge")
              }
        })
    }

    const IncrementStamps = () => {
        setStamps(stamps+1)
    }

    const DecrementStamps = () => {
        if(stamps === 0) { 
            setStamps(0)
        } else {
            setStamps(stamps - 1)
        }
    }

    return(

       returnHome ?

       <Redirect to="/"/>

       :

        <div className="input-amount-container">
              <ToastContainer  />
                <Row style={{width: '100%'}}>
<div style={{alignSelf: 'flex-start'}}>
                       <Link to="/">
                            <Button>
                            <FontAwesomeIcon style={{alignSelf: 'center'}} icon={faArrowLeft}/>
                            <span style={{marginLeft: 5, marginRight: 5}}>Back</span>
                  
                        </Button>
                       </Link>
                    </div>
                </Row>
                <br />
                <Row>
                    
                        <div style={{alignSelf: 'center', justifySelf: 'center'}} className="amount-bar">
                        <span className="amount-header">£{amount}</span>
                    </div>
                
               
                    
                    
                </Row>
                <Row>
                    <Terminal amount={amount} setAmount={setAmount} />
                </Row>
                <Row>
                   <Button style={{marginLeft: 5, marginRight: 5}} className="chargeButton" onClick={createPaymentAmount} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}> Charge</span>
</Button>
{
    userData.rewards.stamp.active || 
    (userData.rewards.percentage_discount.active && userData.rewards.percentage_discount.conditions.on_terminal_only)

    ?

    <Button style={{marginLeft: 5, marginRight: 5}} style={{color: '#fff', backgroundColor: '#6166B3'}} onClick={handleOpen} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}>Rewards</span>
</Button>

:

null
}
    
    
                
                </Row>

                {
                    percentage > 0 ? 

                    <Row style={{paddingTop: 10, paddingBottom: 10}}>
                        <p style={{color: '#65ccb8', textAlign: 'center'}}>Percentage discount applied.</p>
                    </Row>

                    :

                     null
                }
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{marginTop: '20px', marginBottom: '50px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <h1>Rewards</h1>
                    
                   {
                       userData.rewards.stamp.active ?
                        <>
                        <Row style={{marginTop: 10, width: '100%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <h3>Stamps</h3>
                        <h1>{stamps}</h1>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button onClick={DecrementStamps} style={{margin: 10, color: '#fff', backgroundColor: '#F4A442'}} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}> -</span>
</Button>
                        <Button onClick={IncrementStamps} style={{margin: 10, color: '#fff', backgroundColor: '#F4A442'}}  variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}> +</span>
</Button>
                    </div>
                    </div>
                </Row>
                {
        stamps > 0 ? 

        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
            <Button style={{marginLeft: 5, marginRight: 5, width: '100%'}} className="chargeButton" onClick={createPaymentAmount} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}> Stamp Only</span>
</Button>
<Button style={{marginLeft: 5, marginRight: 5, width: '100%'}} className="chargeButton" onClick={handleClose} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}> Add</span>
</Button>
        </div>

:

null
    }

                   </>
    :

    null
                   }
                   {
                       (userData.rewards.percentage_discount.active && userData.rewards.percentage_discount.conditions.on_terminal_only) || 
                       (userData.rewards.percentage_discount.active && userData.rewards.percentage_discount.conditions.student_discount_only)
                       ?

                        <div style={{marginTop: '50px', marginBottom: '20px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <h3>Percentage Discount</h3>

                            {
                                percentage > 0 ?

                                <Button style={{marginLeft: 5, marginRight: 5}} style={{color: '#fff', backgroundColor: '#CD1818'}} onClick={() => {
                                setPercentage(0)
                                handleClose()
                            }} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}>Remove {userData.rewards.percentage_discount.percentage_discount*100}% Discount</span>
</Button>

                                :

                                <Button style={{marginLeft: 5, marginRight: 5}} style={{color: '#fff', backgroundColor: '#6166B3'}} onClick={() => {
                                setPercentage(userData.rewards.percentage_discount.percentage_discount)
                                handleClose()
                            }} variant="contained" color="inherit" disableElevation>
        <span style={{color: "#fff"}}>Apply {userData.rewards.percentage_discount.percentage_discount*100}% Discount</span>
</Button>
                            }
                        </div>
                       :

                       null
                   }
                </div>
        </Box>
      </Modal>
                 
        </div>
    )
}


export default InputAmount;