import React, {useState, useEffect, useContext} from 'react'
import {Container, Row, Col} from 'reactstrap';
import EposNavbar from '../navbar/EposNavbar';

import Lottie from 'react-lottie';

import SearchAnimation from '../../lottie/search-lottie.json';
import UsersContainer from './UsersContainer';

function MainLayout({ socketId, setSocketId, setPaymentActive, setPaymentComplete, setPaymentCancelled, connectedUsers}) {

    const [usersPresent, setUsersPresent] = useState([
        // {
        //     first_name: 'Joe',
        //     socket_id: '123'
        // },
        
    ]);


      const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: SearchAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };



    return (
        <Container fluid>
            <Row>
                <EposNavbar />
            </Row>
            <Row>
                {
                    connectedUsers.length ? 

                        <div className="main-layout-container">
                    <UsersContainer users={connectedUsers} />

                        </div>

                    :

                    <div className="main-layout-container" >
                     <Lottie options={defaultOptions}
              height="30%"
              width="30%"
                style={{margin: '10px auto'}}
              />
                    <h3>No users here...</h3>
                    <p style={{textAlign: 'center'}}>When a user scans your stores QR code or clicks your payment link they will appear here!</p>


                </div>
                }
            </Row>
        </Container>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: 20,
    }
}

export default MainLayout
