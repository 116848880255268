import React from 'react'
import {Row, Col} from 'reactstrap';
import Fab from '@material-ui/core/Fab';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'


const numbers = [
    {
        amount: '1'
    },
    {
        amount: '2'
    },
    {
        amount: '3'
    },
    {
        amount: '4'
    },
    {
        amount: '5'
    },
    {
        amount: '6'
    },
    {
        amount: '7'
    },
    {
        amount: '8'
    },
    {
        amount: '9'
    },
    {
        amount: '0'
    },
    {
        amount: '.'
    },
    {
        amount: <FontAwesomeIcon icon={faArrowLeft}/>
    }
]

function Terminal({amount, setAmount}) {

    const backSpace = () => {}

    return (
        <div>

            <Row style={{margin: '20px auto'}}>
                {
                numbers.map(data => {
                    return(
                        <Col className="number-containers" style={{textAlign: 'center'}} xs="4"><Fab disableRipple onClick={() => {

                            if(data.amount==='.' && amount.includes('.')) {
                                return alert("Must be in the form £0.00 only.")
                            }
                            if(amount === '0.00') {
                                if(data.amount === numbers[11].amount) {
                                    setAmount('0.00')
                                } else if(data.amount === '.') {
                                    setAmount('0.')
                                } else {
                                    setAmount(data.amount)
                                }
                            } else 
                            if(data.amount === numbers[11].amount) {
                           
                                setAmount('0.00')
                            } else {
                                setAmount(amount + data.amount) 
                            }

                        }} style={{width: '70px', height: '70px', boxShadow: "0 7px 14px 0 rgb(59 65 94 / 10%)"}} className="epos-nav-fab" aria-label="add"><span className="button-number">{data.amount}</span></Fab></Col>
                    )
                })
            }
            </Row>
            
        </div>
    )
}

export default Terminal
