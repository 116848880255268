import React, {useState} from 'react'
import Login from './Login'

import './authentication.css'

function AuthLayout() {


    return (
        <div className="auth-container">

                <Login  />

        </div>
    )
}

export default AuthLayout
