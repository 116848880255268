import React, {useState} from 'react';

import {Spinner} from 'reactstrap'

import Button from '@material-ui/core/Button';

import Lottie from 'react-lottie';
import PaymentCompleteAnimation from '../../lottie/PaymentComplete.json'

import { useHistory } from "react-router-dom";


const PaymentComplete = ({setPaymentComplete}) => {

  const history = useHistory();


    const [stopped, setStopped] = useState(false);
    const [paused, setPaused] = useState(false)

      const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: PaymentCompleteAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };

    const handleCancel = () => {
      history.push("/");
      setPaymentComplete(false)
    }

    return(

        <div>
            <div style={{display: 'flex', flexGrow: 4, justifyContent: 'center', alignItems: 'center'}}>
                <Lottie options={defaultOptions}
              height={120}
              width={120}
                style={{margin: '10px auto'}}
              />
            </div>
            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                <h3>Payment was successful! Thank you.</h3>

            </div> 
            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Button color="secondary" disableElevation onClick={handleCancel}>Return</Button>

            </div>
        </div>

    )

}

export default PaymentComplete;