
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';


export default function HandleError(status, message= "Oops... something went wrong. Please try again") {
    switch (status) {
        case 400:
            toast.warn(message, {
                position: toast.POSITION.TOP_CENTER
              });
        break;
        case 401:
            toast.warn(message, {
                position: toast.POSITION.TOP_CENTER
              });
        break;
        case 500:
            toast.warn("Oops... something went wrong on our end. We are looking into it.", {
                position: toast.POSITION.TOP_CENTER
              });
        break;
        default:
            return
}}