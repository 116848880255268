import React, {useState} from 'react';

import {Spinner} from 'reactstrap'

import Button from '@material-ui/core/Button';

import Lottie from 'react-lottie';
import PaymentCancelledAnimation from '../../lottie/PaymentCancel.json'

import { useHistory } from "react-router-dom";


const PaymentCancelled = ({setPaymentCancelled}) => {

  const history = useHistory();


    const [stopped, setStopped] = useState(false);
    const [paused, setPaused] = useState(false)

      const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: PaymentCancelledAnimation,
      rendererSettings: { 
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const handleCancel = () => {
      setPaymentCancelled(false)
        history.push("/");

    }

    return(

        <div>
            <div style={{display: 'flex', flexGrow: 4, justifyContent: 'center', alignItems: 'center'}}>
                <Lottie options={defaultOptions}
              height={120}
              width={120}
                style={{margin: '10px auto'}}
              />
            </div>
            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                <h3>Payment was cancelled! Please try again. If the problem persists then contact us immediately at support@vizopay.com</h3>

            </div> 
            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
            <Button color="secondary" disableElevation onClick={handleCancel}>Return</Button>

            </div>
        </div>

    )

}

export default PaymentCancelled;